<template>
  <div>
    <v-row>
      
      <v-col cols="12" sm="12" md="12" class="text-right pl-5">
        <v-row>
          <v-col cols="6" sm="6" md="3">
              <v-text-field
              label="Enter Amount"
              v-model="searchParam.amount"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="3">
            <v-text-field
              label="Duration (sec)"
              v-model="searchParam.duration"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="4" sm="6" md="3">
            <v-text-field
              label="Search Server ID"
              v-model="searchParam.server_id"
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="8" sm="6" md="3" class="text-left">
            <v-btn
              @click="getData()"
              class="white--text mr-2"
              color="green darken-1"
              depressed
            >
              {{ $t("button.search") }}
            </v-btn>
            <v-btn
              @click="clear()"
              class="white--text"
              color="red darken-1"
              depressed
            >
              {{ $t("button.clear") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    searchParam: {
      type: Object,
      default: null,
      }
  },
  data() {
    return {
      // search: {
      //   amount: 0,
      //   duration: 0,
      //   server_id: ""
      // }
    };
  },
  watch: {
    
  },
  computed: {},
  methods: {
    async getData() {
      this.$emit("submit-data", this.searchParam);
    },
    async clear() {
      this.$emit("clear-data");
    },
  },
};
</script>
<style>
.btn-search-date {
  padding: 20px 25px !important;
  font-size: 18px !important;
  margin-right: 40px !important;
}
.report-title {
  font-weight: 700 !important;
  position: relative;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 36px;
  padding: 2px 20px;
  color: #27448c;
  margin: 0;
  width: 100%;
  height: 36px;
  font-size: 25px;
}
</style>
